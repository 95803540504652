import { api, i18n } from "@/config"
import { flatMap, map } from "lodash-es"
import { handleFailedResponse } from "@/helpers/common"
import { showToast } from "@/helpers/toasts"

const buildMarkupParams = ({ basic_rates, special_rates }) => [
  ...mapMarkupFee(basic_rates),
  ...mapSpecialRateMarkupFee(special_rates)
]

const mapSpecialRateMarkupFee = specialRates => {
  return flatMap(specialRates, ({ rates }) => mapMarkupFee(rates))
}

const mapMarkupFee = rates => {
  return map(rates, ({ id, markup_fee }) => ({ plan_price_row_id: id, ...markup_fee }))
}

export default ({ baseURI }) => ({
  FETCH_ITEM: async ({ commit }, id) => {
    try {
      const { data } = await api.get(`${baseURI}/${id}`)
      commit("SET_ITEM", data.data)
    } catch (e) {
      handleFailedResponse(e)
    }
  },

  UPDATE_MARKUPS: async ({ commit }, { plan_id, publish_settings: { released, ...rest } }) => {
    try {
      const params = { plan_markup: { released, markups: buildMarkupParams(rest) } }
      const { data } = await api.post(`${baseURI}/${plan_id}/update_markups`, params)
      commit("SET_ITEM", data.data)
      showToast({
        title: i18n.t("success"),
        text: i18n.t("ota_management.plan_markups_updated"),
        variant: "success"
      })
    } catch (e) {
      handleFailedResponse(e)
    }
  }
})
